*:focus
	outline none
.footerMain
	background-color cDark
	display flex
	justify-content space-around
	align-items center
	height 80%
.txtArea
	width 20%
	height 20%
	resize none
.footerContact
	width 40%
	height 80%
	p
		color cLight
		font-family BebasBold
		font-size 6vmax
	hr
		width 30%
		margin-bottom 4%
	a 
		color cLight
		font-family FuturaLight
		font-size 2.5vmax
		text-decoration none
		line-height 3.5vmax
		position relative
		transition color 1s
		
	a:hover
		color cRed
		transition color 1s
	.sity
		color cLight
		font-family FuturaLight
		font-size 2.5vmax
		text-decoration none
		line-height 3.5vmax		
.FooterForm
	padding-top 5%
	height 85%
	align-self center
	display flex
	flex-direction column
	justify-content space-between
	align-items center
	width 40%
	font-family BebasLight
	font-size 2vmax
	.txt
		width 100%
		height 15%
		font-family FuturaLight
		font-size 2vmax
		background-color cDark
		border-style solid
		border-width 0px 0px 1px 0px
		color cLight
		padding-left 8px
	.txtArea
		width 100%
		height 40%
		padding-left 8px
		font-family FuturaLight
		font-size 2vmax
		border-style solid
		color cLight
		background-color cDark
		border-width 0px 0px 1px 0px
		border-color cLight
	.formSubmit
		width 30%
		height 3vmax
		padding-top 0.3vmax
		background-color cDark
		align-self flex-end
		border-style solid
		border-color rgba(255, 255, 255, 0.1)
		color white
		font-family BebasBold
		font-size 1.5vmax
		transition border-color 1s
		.formArrow
			transition color 1s
::-webkit-input-placeholder {color:cLight; opacity: 1;}
::-moz-placeholder          {color:cLight; opacity: 1;}/* Firefox 19+ */
:-moz-placeholder           {color:cLight; opacity: 1;}/* Firefox 18- */
:-ms-input-placeholder      {color:cLight; opacity: 1;}
::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
.txt:focus
	border-color cRed
.txtArea:focus
	border-color cRed
.formSubmit:hover
	cursor pointer
	border-color white
	transition border-color 1s
	.formArrow
		transition color 1s
		color cRed
.copyRihtMine
	display flex
	background-color cDark
	height 20%
	justify-content space-around
	align-items center
.copyRiht
	width 50%
	color grey
	font-family FuturaLight
	font-size 2vmin
.container_button
	display flex
	width 100%
	justify-content space-around
	align-items center
.result
	font-family FuturaLight
	font-size 1.5vmax
	color cRed
	padding-top 0.05vmax
.fa-envelope , .fa-vk
	font-size 2.2vmax !important
	vertical-align 7%
		