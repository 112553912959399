#about
	display flex
	overflow hidden
.aboutImg
	background-image url(../img/About_BG.png)
	background-position 60% 0%
	background-size cover
	width 50%
	height 100%
.abouttext
	width 100%
	height 100%
	display flex
	flex-direction column
	font-family FuturaLight
	justify-content space-around
	font-size 2.2vmax
	z-index 2
.ll
	width 50%
	height 100%
	position relative
	background-color white !important
	
.abTxtMain
	min-width 20vmax
	width 80%
	margin 5%
	align-self center
.abTxtTheme
	margin-right 5%
	align-self flex-end
	font-family BebasBold
	font-size 6vmax
a 
	color cDark
	text-decoration none
	transition color 0.8s
		
a:hover
	color cRed
	transition color 0.8s
.aboutContacts
	width 100%
	z-index 2
	font-family FuturaBook
	font-size 1.5vmax
	margin-left 5%
.aboutform
	margin-left -5%
	display flex
	justify-content center


		
		