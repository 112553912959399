drawHam(l=13px)
	display block
	position absolute
	left l
	right 13px
	height 3px
	background cRed
body
	section.d
		position relative
		transform-style preserve3d
		perspective 5000px
		background-color cDark

		#Main 
			transition all 2s ease-in-out
			.mainTxt
				background-color white !important
				div
					background-color white !important
		#IDo
			transition all 2s ease-in-out
			
		.portfolioMain
			transition all 2s ease-in-out
			
		#education
			transition all 2s ease-in-out
			
		#about
			transition all 2s ease-in-out
			
		#skill
			transition all 2s ease-in-out
			
		footer
			border-color white
			border-style solid
			border-width 0px 0px 0px 0px
			transition all 2s ease-in-out
			
		
		#Main.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
		#IDo.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
		.portfolioMain.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
		#education.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
		#about.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
		#skill.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
		footer.active
			cursor pointer
			transform-origin 100% 0% 
			transform rotateY(-35deg)
			border-width 0.1px 0px 0.1px 0.1px


.Menu
	z-index 1
	position fixed
	nav
		// display flex
		// flex-direction column
		//width 3000px
		//background-color rgba(255,255,255,0)
		//transition background-color 0.5s
		//&.active
			//background-color rgba(255,255,255,0.8)
		ul
			display flex
			flex-direction column
			//height 55px
			align-items center
			perspective 400px
			perspective-origin bottom left
			li
				//margin 10px
				font-family FuturaBook
				font-size 30px
				padding 10px
				list-style-type none
				align-self flex-start
				a
					text-decoration none
					color white
					cursor pointer
					hr
						width 25%
						opacity 0
						transition-property width, opacity
						transition-delay 0s, 0s
						transition-duration 1s,0.5s
					hr.actv
						opacity 1
				
.spsk:hover
	a
		hr
			width 100%
			opacity 1
			transition-delay 0s
.spskUl
	overflow hidden
	width 1px
	opacity 0
	transition width, opacity
	transition-duration 0s,2s
	transition-delay 2s, 0s
	&.active
		width 200px
		opacity 1
		transition-duration 0s,2s
		transition-delay 0s
.humburger
	display block
	position relative
	overflow hidden
	width 55px
	height 55px
	cursor pointer
	appearance none
	background-color rgba(255,255,255,0)
	box-shadow none
	border none
	border-radius none
	transition background 0s 0.3s
	&:focus
		outline none
	span
		top 25px
		drawHam()
		&::before
			top -10px
			transition-property top, transform
		&::after
			bottom -10px
			transition-property bottom, transform
		&::before,
		&::after
			drawHam(0)
			width 100%
			content ""
			background-color cRed
			transition-duration 0.3s, 0.3s
			transition-delay 0.3s, 0.3s, 0s
	&.active
		background-color rgba(255,255,255,0)
		span
			background none
			&::before
				top: 0px
				transform rotate(45deg)
			&::after
				bottom: 0px
				transform rotate(-45deg)
			&::before,
			&::after
				transition-delay 0s, 0.3s, 0.3s

