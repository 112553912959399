
#skill
	width 100%
	display flex
	justify-content center
	align-items center
	background-color white !important
	svg
		padding-right 5%
		flex-grow 1
.graph
	width 70%
	height 100%
.sk
	flex-grow 1
	width 39%
	display flex
	flex-direction column
	align-items center
	position relative
	overflow hidden
.textGr
	text-anchor end
	fill cDark
	font-size 2.3vmax
	font-family FuturaMedium
.grid-line
	stroke #000
	shape-rendering crispedges
	stroke-opacity 0.2
	stroke-width 2
.x-axis
	text
		font-family FuturaMedium
		fill cDark
		font-size 3.2vmin
.domain
	stroke-opacity 0
.tick
	stroke-opacity 0
.bld
	font-family FuturaMedium
.skTxt
	width 80%
	padding-top 1.5vmax
.sk
	font-family FuturaBook
	height 90%
	font-size 2.2vmax
	color cDark
.themeSkill
	textDark(6vw,BebasBold)
.skillHr
	width 45%