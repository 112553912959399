#IDo
	background-image url(../img/Do_bg.jpg)
	background-size cover
	background-position 40% 0%
	box-sizing border-box
	display flex
	flex-direction column
	justify-content space-around
	padding 2%
	overflow hidden
	.doTheme
		font-family BebasBold
		font-size 5vmax
		color cDark
		p
			span
				font-family BebasLight
.doWeb
	align-self flex-start
	width 40%
	font-family FuturaMedium
	font-size 2.2vmax
	color cDark
	hr
		width 50%
.doWebTxt
	font-family FuturaBold
	font-size 6vmax
	color #FFFFFF
.doFooter
	width 96%
	justify-content space-around
	display flex
	position relative
.doDesign
	margin auto
	width 50%
	font-family FuturaMedium
	font-size 2.2vmax
	color cDark
	z-index 2
	hr
		width 50%
		z-index 1
.doDesignTheme
	font-family FuturaBold
	font-size 6vmax
	color #FFFFFF
	z-index 2
.doCount
	font-size 45vh
	font-family BebasBold
	line-height 100%
	color #FFFFFF
	position absolute
	right 85%
	top 30%
	z-index 1
.doZakaz
	align-self flex-end
	.doZakazTxt
		font-family BebasBold
		font-size 1.9vmax
		color cDark
		span
			color #1ed600
			font-size 3.8vmax
			line-height 1px
	hr
		width 30%
	.doZakazCont
		color #04b4f3
		font-family FuturaBook
		font-size 1.7vw
		cursor pointer
		&:hover
			color cRed
.percentDiag
	fill cLight
	font-family FuturaLight
	font-size 1.5vmax
	opacity 0
	transition opacity 1s
	cursor pointer
.actve
	opacity 1
rect 
	cursor pointer
	&:hover
		fill rgb(176,47,25) !important
		transition fill 1s


	
	
	
	