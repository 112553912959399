#education
	width 100%
	height 100%
	display flex
	flex-direction column
	justify-content space-between
	background-size cover
	background-position 50% 50%
	background-image url(../img/education.jpg)
.educationMain
	display flex
	padding 2%
	justify-content space-around
	.educationTheme
		padding-top 1.2vmax //выравнивание текста по бордеру
		padding-right  2vmax
		font-family BebasBold
		font-size 7vmax
		color rgba(251,247,253,1)
		border-style solid
		border-color cRed
		border-width 0px 3px 0px 0px
		box-sizing border-box
	.University
		width 100%
		display flex
		flex-direction column
		padding-left 1%
		color cDark
.vgu
	font-family FuturaBold
	color cDark
	font-size 3.5vmax
	span
		font-family FuturaBook
		color cRed
		font-size 2vmax
.facultet
	font-family FuturaMedium
	font-size 2.5vmax
.spec
	font-family FuturaLight
	font-size 2vmax
.University2
	align-self center
	.vgu
		padding-left 1vmax
		border-width  0px 0px 0px 2px
		border-style solid
		border-color cRed
.educationRead
	// display flex
	// justify-content space-between
	position relative
	align-items center
// .educationCount
// 	position absolute
// 	font-size 45vh
// 	font-family BebasBold
// 	line-height 100%
// 	color #FFFFFF
// 	right 0
// 	top 46%
// 	z-index 1
.readText
	overflow hidden
	align-self flex-end
	margin 10px
	hr
		width 10%
.nowRead
	font-family BebasBold
	color cRed
	font-size 3vmax
.book
	font-family FuturaMedium
	font-size 2.5vmax
	color cDark
	margin-bottom 10px
	span
		font-family FuturaBook
		font-size 2vmax
.spisok
	text-decoration none
	color #04b4f3
	font-family FuturaBook
	font-size 2vmax


		