
.background 
    overflow hidden
    will-change transform
    backface-visibility hidden
    height 130vh
    position fixed
    width 100%
    transform translateY(30vh)
    transition all 1.2s cubic-bezier(0.22, 0.44, 0, 1)
    &:before 
        content ""
        position absolute
        width 100%
        height 100%
        top 0
        left 0
        right 0
        bottom 0
        background-color rgba(0, 0, 0, 0.3)
    &:first-child 
        transform translateY(-15vh)
        .content-wrapper 
            transform translateY(15vh)
    &:nth-child(2) 
        z-index 6
    &:nth-child(3) 
        z-index 5
    &:nth-child(1) 
        z-index 7
    &:nth-child(4) 
        z-index 4
    &:nth-child(5) 
        z-index 3
    &:nth-child(6) 
        z-index 2
    &:nth-child(7) 
        z-index 1
    &.up-scroll 
        transform translate3d(0, -15vh, 0)
        .content-wrapper 
            transform translateY(15vh)
        & + .background 
            transform translate3d(0, 30vh, 0)
            .content-wrapper 
                transform translateY(30vh)
    &.down-scroll 
        transform translate3d(0, -130vh, 0)
        .content-wrapper 
            transform translateY(40vh)
        & + .background 
            &:not(.down-scroll) 
                transform translate3d(0, -15vh, 0)
                .content-wrapper 
                    transform translateY(15vh)
.content-wrapper 
    height 100vh
    display flex
    justify-content center
    flex-flow column nowrap
    transform translateY(40vh)
    will-change transform
    backface-visibility hidden
    transition all 1.7s cubic-bezier(0.22, 0.44, 0, 1)